import { veterinary } from '@/axios'

export default {
  getAllDoctor (query = '') {
    return veterinary().get(`customer/doctors?${query}`)
  },
  getAllReservedTime (id, day, date) {
    return veterinary().get(`customer/reservations/reserved-times/${id}?day=${day}&date=${date}`)
  },
  getReservationsCount (id, day, date) {
    return veterinary().get(`customer/reservations/reservations-count/${id}?day=${day}&date=${date}`)
  },
  reservation (payload) {
    return veterinary().post('customer/reservations', payload)
  },
  getAllReservation (page) {
    return veterinary().get(`customer/reservations?page=${page}`)
  }
}
