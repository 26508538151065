<template>
  <b-row>
    <b-col lg="12">
      <iq-card class="px-4 py-3">
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <div class="pl-2">
              <slot name="header-icon"></slot>
            </div>
            <slot name="header-title"></slot>
<!--            <p class="text-primary font-size-22 m-0 p-0"></p>-->
          </div>

          <b-button
              variant="primary"
              class="px-4 py-2"
          >
            <slot name="btn-name"></slot>
<!--            <span class="font-size-16"></span>-->
          </b-button>
        </div>
      </iq-card>
    </b-col>
  </b-row>
</template>

<script>
import { core } from '@/config/pluginInit'

export default {
  props: {
    multiLayouts: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      selectedItem: 1
    }
  },
  methods: {
    changeWidth () {
      if (window.innerWidth <= 992) {
        this.$emit('changeLayout', 'vertical')
        this.selectedItem = 1
      }
    }
  },
  mounted () {
    core.index()
    window.addEventListener('resize', this.changeWidth)
  }
}
</script>

<style lang="scss">
.filter {
  .active path {
    fill: var(--iq-primary) !important;
  }
  svg {
    width: 28px;
    cursor: pointer;
    path {
      fill: #9B9B9B;
    }
  }
}
</style>
